import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Particular() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div style={{ margin: "0 20px" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2em",
                marginBottom: "30px",
              }}
            >
              特定商取引法表示
            </p>
            <p style={{ fontWeight: "bold" }}>■事業者の名称</p>
            <div style={{ margin: "16px" }}>株式会社　グッドフェローズ</div>
            <p style={{ fontWeight: "bold" }}>■通信販売業務の責任者</p>
            <div style={{ margin: "16px" }}>代表取締役　磯部　昌美</div>
            <p style={{ fontWeight: "bold" }}>■所在地</p>
            <div style={{ margin: "16px" }}>
              東京都武蔵野市中町1-15-5　三鷹髙木ビル7F
            </div>
            <p style={{ fontWeight: "bold" }}>■電話番号</p>
            <div style={{ margin: "16px" }}>
              0422-27-8701(代表)
              <br />※ 電話受付時間（平日9：30-17：00）
            </div>
            <p style={{ fontWeight: "bold" }}>■メールアドレス</p>
            <div style={{ margin: "16px" }}>support@tshiia.xyz</div>
            <p style={{ fontWeight: "bold" }}>■商品の販売価格</p>
            <div style={{ margin: "16px" }}>
              商品ごとに販売価格は異なります。商品販売ページをご確認ください。
            </div>
            <p style={{ fontWeight: "bold" }}>■商品代金の決済方法</p>
            <div style={{ margin: "16px" }}>
              ・クレジットカード決済
              <br />
              VISA、MasterCard、JCBと、それらの提携カードがご利用頂けます。
            </div>
            <div style={{ margin: "16px" }}>
              ・コンビニ決済
              <br />
              セブンイレブン、ファミリーマート、ローソン、ミニストップでお支払い頂けます。
            </div>
            <div style={{ margin: "16px" }}>
              ・QRコード決済
              <br />
              PayPay、Alipayがご利用頂けます。
            </div>
            <div style={{ margin: "16px" }}>
              ・楽天ペイ
              <br />
              対象クレジットカード：VISA、MasterCard、JCBと、それらの提携カードがご利用頂けます。
            </div>
            <div style={{ margin: "16px" }}>・銀聯ネット決済</div>
            <div style={{ margin: "16px" }}>
              ※コンビニ決済、QRコード決済、楽天ペイ、銀聯ネット決済については、ご利用いただけない集客施設もございます。あらかじめご了承ください。
            </div>
            <p style={{ fontWeight: "bold" }}>■商品代金以外に必要となる費用</p>
            <div style={{ margin: "16px" }}>
              インターネット接続料金、その他電気通信回線の通信に関する費用は、お客様にて別途ご用意いただく必要があります。
            </div>
            <p style={{ fontWeight: "bold" }}>■商品代金の支払時期</p>
            <div style={{ margin: "16px" }}>
              ・クレジットカード決済、銀聯ネット決済
              <br />
              ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。
            </div>
            <div style={{ margin: "16px" }}>
              ・コンビニ決済
              <br />
              お支払い期日は商品により異なります。商品の販売ページをご確認ください。
            </div>
            <div style={{ margin: "16px" }}>
              ・QRコード決済
              <br />
              注文確定時に支払われます。
            </div>
            <div style={{ margin: "16px" }}>
              ・楽天ペイ
              <br />
              楽天ペイにご登録のクレジットカードにより異なります。ご登録されたカード会社までお問い合わせください。
            </div>
            <p style={{ fontWeight: "bold" }}>■購入後のキャンセル</p>
            <div style={{ margin: "16px" }}>
              購入手続が完了した商品については、返品、交換またはキャンセルを承りません。
              <br />
              但し、施設によってはキャンセルが可能な場合もありますので、各施設のチケット販売ページをご確認ください。
              <br />
              なお、キャンセル可能なチケットの一部につきましては、キャンセル手数料が発生します。
              <br />
              商品に瑕疵がある場合は、利用規約の定めに従って対応します。
              <br />
              特別な販売条件または、提供条件がある商品については、各商品の販売ページにおいて条件を表示します。
            </div>
            <p style={{ fontWeight: "bold" }}>■商品の引渡し時期</p>
            <div style={{ margin: "16px" }}>
              購入完了時にチケットを「購入完了画面」または「購入完了メール」で配信します。
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Particular;
