import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [name, setName] = useState({ lastName: "", firstName: "" });
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const contactSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.lastName || !name.firstName) newErrors.name = "Enter a full name";
    if (!mail) newErrors.mail = "Enter an email";
    if (!phone) newErrors.phone = "Enter a phone number";
    if (!message) newErrors.message = "Enter a message";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      alert("送信が成功しました！");
      setName({ lastName: "", firstName: "" });
      setMail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="alignLeft">
            <b>
              <span className="linkUnderScore">
                <Link to="/pc/about/index?mid=01" target="_blank">
                  「利用方法」
                </Link>
              </span>
              <span className="linkUnderScore">
                <Link to="/pc/about/index?mid=06" target="_blank">
                  「よくあるご質問」
                </Link>
              </span>
              をご確認頂き、解決できない場合がございましたら下記よりお問い合わせください。
            </b>
          </div>
          <div className="frame">
            <div className="contact_first">
              <span className="underScore">
                <b>施設または商品に関するお問い合わせ</b>
              </span>
            </div>
            <ul className="list c-disc">
              <li>チケットの内容や利用方法</li> <li>施設の営業に関すること</li>
              <li>施設の混み具合について</li>
            </ul>
            <div className="caution">
              ※操作方法やご利用環境、操作中のエラーについては
              <br />
              　株式会社グッドフェローズへお問い合わせください。
            </div>
            <div className="svg__div">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
              </svg>
            </div>
            <div>
              <div className="facilityContact">
                <div className="c-fwB c-tCenter">
                  <center>各施設へ直接お問い合わせください。</center>
                </div>
              </div>
            </div>
          </div>

          <div className="frame">
            <div className="contact_first">
              <span className="underScore">
                <b>本サイト（Webket）に関するお問合せ</b>
              </span>
            </div>
            <ul className="list c-disc">
              <li>操作方法に関すること</li> <li>本システムに関すること</li>
            </ul>
            <div className="svg__div">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
              </svg>
            </div>
            <div className="company">
              <div className="c-fwB c-tCenter">株式会社グッドフェローズ</div>
              <div className="phone__div">
                <ul className="fa-ul">
                  <li>
                    <img
                      width="14"
                      height="14"
                      src="https://img.icons8.com/ios-glyphs/50/phone--v1.png"
                      alt="phone--v1"
                    />
                    <Link to="tel:0422276238">0422-27-6238</Link>
                  </li>
                  <li>
                    <img
                      width="14"
                      height="14"
                      src="https://img.icons8.com/fluency-systems-filled/50/new-post.png"
                      alt="new-post"
                    />
                    <Link to="/" target="_blank">
                      support@tshiia.xyz
                    </Link>
                  </li>
                  <li>
                    <img
                      width="14"
                      height="14"
                      src="https://img.icons8.com/material-outlined/50/external-link.png"
                      alt="external-link"
                    />
                    <Link to="/" target="_blank">
                      http://goodf.co.jp/
                    </Link>
                  </li>
                  <li>
                    <img
                      width="14"
                      height="14"
                      src="https://img.icons8.com/material-rounded/50/clock.png"
                      alt="clock"
                    />
                    9:30～17:00
                  </li>
                </ul>
              </div>
            </div>
            <div className="caution">
              ※お問合せの際は、ご利用予定の施設名をお伝えください。
              <br />
              ※support@tshiia.xyz
              よりご返信させていただきます。メールの受信設定をお願いいたします。
              <br />
              ※メールでお問合せいただきました際、お問合せの内容によりお電話にてご連絡させていただく場合がございます。
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
